
import BielbitDropDownItem from '~/components/BielbitDropDownItem.vue'
import BielbitMainDropDownItem from '~/components/BielbitMainDropDownItem.vue'

export default {
  name: 'DefaultLayout',
  components: { BielbitMainDropDownItem, BielbitDropDownItem },
  data() {
    return {
      modal: {
        id: 'globalPopupModal',
        action: 'beforeInventory',
      },
      home: 'Strona głowna',
      aboutUs: 'O nas',
      news: 'Aktualności',
      software: 'Oprogramowanie',
      insert: 'InsERT',
      offer: 'Oferta',
      ourProjects: 'Realizacje',
      cybersecurity: 'Cyberbezpieczeństwo',
      equipmentAndService: 'Sprzęt i Serwis',
      accountingOffice: 'Biuro Rachunkowe',
      electronicSignature: 'Podpis Elektroniczny',
      helpForCustomers: 'Dla Partnerów',
      career: 'Kariera',
      faq: 'FAQ',
      contact: 'Kontakt',
      secretariatTelephoneNumber: '33 472 33 33',
      secretariatMailAddress: 'bielbit@bielbit.pl',
    }
  },
  head() {
    const head = {
      link: [],
    }
    head.link.push({
      rel: 'preload',
      as: 'image',
      href: `/bielbit_logo_standard.svg`,
    })
    head.link.push({
      rel: 'preload',
      as: 'image',
      href: `/favicon.svg`,
    })
    head.link.push({
      rel: 'preload',
      as: 'image',
      href: `/logo_poziom_bialy.svg`,
    })
    return head
  },
  computed: {
    activeClassForNavItem() {
      return this.$colorMode.preference === 'dark' ? 'text-white' : 'text-dark'
    },
  },
  async mounted() {
    const currentDate = new Date().toISOString()
    const splittedCurrentDate = currentDate.split('T')
    const currentDateAaNumber = Number(
      splittedCurrentDate[0].replaceAll('-', ''),
    )
    // console.log('After 2023-12-22: ' + (currentDateAaNumber > 20231222 ? "YES" : "NO"))
    // console.log('No after 2023-12-22: ' + (currentDateAaNumber <= 20231222 ? "YES" : "NO"))
    if (
      !this.$cookies.get(this.modal.action) &&
      currentDateAaNumber <= 20241129
    )
      setTimeout(() => {
        console.log(new Date())
        // Kod, który ma zostać wykonany po 10 sekundach
        console.log('Otwieram modal z komunikatem po 3 sekundach')
        this.$bvModal.show(this.modal.id)
      }, 3000) // 3000 milisekund = 3 sekund
    // console.log(this.$config.website.envVariables)
    const workbox = await window.$workbox
    if (workbox) {
      workbox.addEventListener('installed', (event) => {
        // If we don't do this we'll be displaying the notification after the initial installation, which isn't perferred.
        if (event.isUpdate) {
          // whatever logic you want to use to notify the user that they need to refresh the page.
          console.log('Update was applied. :-D')
          // this.$bvToast.show('update-toast')
        }
      })
    }
    const dropdowns = document.getElementsByClassName('dropdown-toggle')
    for (let index = 0; index < dropdowns.length; index++) {
      const element = dropdowns[index]
      for (let i = 0; i < element.children.length; i++) {
        const child = element.children[i]
        element.setAttribute('title', 'Rozwiń menu ' + child.textContent)
      }
    }
    if (this.$device.isSamsung) this.setColorModeTheme('dark')
    if (!this.$cookies.get('isCookiesAccepted'))
      this.$bvToast.show('cookies-toast')
  },
  methods: {
    acceptCookies() {
      this.$cookies.set('isCookiesAccepted', true, {
        maxAge: 60 * 60 * 24 * 31,
      })
      this.$bvToast.hide('cookies-toast')
    },
    closeUpdateToast(reload) {
      this.$bvToast.hide('update-toast')
      if (reload === true) window.location.reload(true)
    },
    setColorModeTheme(color) {
      this.$colorMode.preference = color
      this.$cookies.set('colorModeTheme', color, {
        maxAge: 60 * 60 * 24 * 366,
      })
    },
    addGoToPagePrefix(page) {
      return 'Przejdź do strony ' + page
    },
  },
}
