import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7d8c8294 = () => interopDefault(import('../pages/aktualnosci.vue' /* webpackChunkName: "pages/aktualnosci" */))
const _22e0349f = () => interopDefault(import('../pages/biuro-rachunkowe.vue' /* webpackChunkName: "pages/biuro-rachunkowe" */))
const _3294b68e = () => interopDefault(import('../pages/cyberbezpieczenstwo.vue' /* webpackChunkName: "pages/cyberbezpieczenstwo" */))
const _40f9fbba = () => interopDefault(import('../pages/insert/index.vue' /* webpackChunkName: "pages/insert/index" */))
const _b9582a40 = () => interopDefault(import('../pages/kadry-i-place/index.vue' /* webpackChunkName: "pages/kadry-i-place/index" */))
const _13f01659 = () => interopDefault(import('../pages/kariera.vue' /* webpackChunkName: "pages/kariera" */))
const _91df465c = () => interopDefault(import('../pages/kontakt.vue' /* webpackChunkName: "pages/kontakt" */))
const _204a9e24 = () => interopDefault(import('../pages/ksiegowosc-i-finanse/index.vue' /* webpackChunkName: "pages/ksiegowosc-i-finanse/index" */))
const _697ea2af = () => interopDefault(import('../pages/nasze-realizacje/index.vue' /* webpackChunkName: "pages/nasze-realizacje/index" */))
const _516d09f4 = () => interopDefault(import('../pages/o-nas.vue' /* webpackChunkName: "pages/o-nas" */))
const _f00f689a = () => interopDefault(import('../pages/obowiazek-informacyjny-firmy.vue' /* webpackChunkName: "pages/obowiazek-informacyjny-firmy" */))
const _994c92b8 = () => interopDefault(import('../pages/oprogramowanie.vue' /* webpackChunkName: "pages/oprogramowanie" */))
const _0b790f67 = () => interopDefault(import('../pages/podpis-elektroniczny.vue' /* webpackChunkName: "pages/podpis-elektroniczny" */))
const _2976d574 = () => interopDefault(import('../pages/pomoc-dla-partnerow/index.vue' /* webpackChunkName: "pages/pomoc-dla-partnerow/index" */))
const _21885921 = () => interopDefault(import('../pages/sprzedaz-i-magazyny/index.vue' /* webpackChunkName: "pages/sprzedaz-i-magazyny/index" */))
const _6c064a20 = () => interopDefault(import('../pages/sprzet-i-serwis.vue' /* webpackChunkName: "pages/sprzet-i-serwis" */))
const _25b15ae8 = () => interopDefault(import('../pages/insert/agralan.vue' /* webpackChunkName: "pages/insert/agralan" */))
const _0f4d05f7 = () => interopDefault(import('../pages/insert/biuro-gt.vue' /* webpackChunkName: "pages/insert/biuro-gt" */))
const _a8dbb210 = () => interopDefault(import('../pages/insert/biuro-nexo.vue' /* webpackChunkName: "pages/insert/biuro-nexo" */))
const _444f88f0 = () => interopDefault(import('../pages/insert/czerwony-plus.vue' /* webpackChunkName: "pages/insert/czerwony-plus" */))
const _13f824a0 = () => interopDefault(import('../pages/insert/gestor-gt.vue' /* webpackChunkName: "pages/insert/gestor-gt" */))
const _aa404298 = () => interopDefault(import('../pages/insert/gestor-gt-sfera.vue' /* webpackChunkName: "pages/insert/gestor-gt-sfera" */))
const _31f03f61 = () => interopDefault(import('../pages/insert/gestor-nexo.vue' /* webpackChunkName: "pages/insert/gestor-nexo" */))
const _e563ab7e = () => interopDefault(import('../pages/insert/gestor-nexo-pro.vue' /* webpackChunkName: "pages/insert/gestor-nexo-pro" */))
const _71a7ec68 = () => interopDefault(import('../pages/insert/gratyfikant-gt.vue' /* webpackChunkName: "pages/insert/gratyfikant-gt" */))
const _153cb07c = () => interopDefault(import('../pages/insert/gratyfikant-gt-sfera.vue' /* webpackChunkName: "pages/insert/gratyfikant-gt-sfera" */))
const _3a6595ae = () => interopDefault(import('../pages/insert/gratyfikant-nexo.vue' /* webpackChunkName: "pages/insert/gratyfikant-nexo" */))
const _10aa07ee = () => interopDefault(import('../pages/insert/gratyfikant-nexo-pro.vue' /* webpackChunkName: "pages/insert/gratyfikant-nexo-pro" */))
const _1901b56d = () => interopDefault(import('../pages/insert/insert-nexo-pro-plus.vue' /* webpackChunkName: "pages/insert/insert-nexo-pro-plus" */))
const _d1247fc4 = () => interopDefault(import('../pages/insert/navireo.vue' /* webpackChunkName: "pages/insert/navireo" */))
const _2a07c45c = () => interopDefault(import('../pages/insert/niebieski-plus.vue' /* webpackChunkName: "pages/insert/niebieski-plus" */))
const _717a2738 = () => interopDefault(import('../pages/insert/portal-biura.vue' /* webpackChunkName: "pages/insert/portal-biura" */))
const _d2a74a6e = () => interopDefault(import('../pages/insert/rachmistrz-gt.vue' /* webpackChunkName: "pages/insert/rachmistrz-gt" */))
const _4f20e16c = () => interopDefault(import('../pages/insert/rachmistrz-nexo.vue' /* webpackChunkName: "pages/insert/rachmistrz-nexo" */))
const _42615daa = () => interopDefault(import('../pages/insert/rachmistrz-nexo-pro.vue' /* webpackChunkName: "pages/insert/rachmistrz-nexo-pro" */))
const _e81818ac = () => interopDefault(import('../pages/insert/rewizor-gt.vue' /* webpackChunkName: "pages/insert/rewizor-gt" */))
const _3582be3e = () => interopDefault(import('../pages/insert/rewizor-gt-sfera.vue' /* webpackChunkName: "pages/insert/rewizor-gt-sfera" */))
const _cb97182a = () => interopDefault(import('../pages/insert/rewizor-nexo.vue' /* webpackChunkName: "pages/insert/rewizor-nexo" */))
const _17f109cb = () => interopDefault(import('../pages/insert/rewizor-nexo-pro.vue' /* webpackChunkName: "pages/insert/rewizor-nexo-pro" */))
const _1760b0c5 = () => interopDefault(import('../pages/insert/sello.vue' /* webpackChunkName: "pages/insert/sello" */))
const _6118d512 = () => interopDefault(import('../pages/insert/subiekt-123.vue' /* webpackChunkName: "pages/insert/subiekt-123" */))
const _2919eabd = () => interopDefault(import('../pages/insert/subiekt-gt.vue' /* webpackChunkName: "pages/insert/subiekt-gt" */))
const _99ac49de = () => interopDefault(import('../pages/insert/subiekt-gt-sfera.vue' /* webpackChunkName: "pages/insert/subiekt-gt-sfera" */))
const _f48e1b84 = () => interopDefault(import('../pages/insert/subiekt-nexo.vue' /* webpackChunkName: "pages/insert/subiekt-nexo" */))
const _d4cfb2c4 = () => interopDefault(import('../pages/insert/subiekt-nexo-pro.vue' /* webpackChunkName: "pages/insert/subiekt-nexo-pro" */))
const _ad87b7e2 = () => interopDefault(import('../pages/insert/subiekt-sprint-2.vue' /* webpackChunkName: "pages/insert/subiekt-sprint-2" */))
const _66a47ff6 = () => interopDefault(import('../pages/insert/vendero.vue' /* webpackChunkName: "pages/insert/vendero" */))
const _1811fb69 = () => interopDefault(import('../pages/insert/zarezerwuj-pl-system-rezerwacji-online.vue' /* webpackChunkName: "pages/insert/zarezerwuj-pl-system-rezerwacji-online" */))
const _0c75ee2a = () => interopDefault(import('../pages/insert/zielony-plus.vue' /* webpackChunkName: "pages/insert/zielony-plus" */))
const _7281f327 = () => interopDefault(import('../pages/kadry-i-place/fundusz-mieszkaniowo-remontowy.vue' /* webpackChunkName: "pages/kadry-i-place/fundusz-mieszkaniowo-remontowy" */))
const _1731540a = () => interopDefault(import('../pages/kadry-i-place/fundusz-swiadczen-socjalnych.vue' /* webpackChunkName: "pages/kadry-i-place/fundusz-swiadczen-socjalnych" */))
const _3e3f528b = () => interopDefault(import('../pages/kadry-i-place/import-indywidualnych-kalendarzy-czasu-pracy.vue' /* webpackChunkName: "pages/kadry-i-place/import-indywidualnych-kalendarzy-czasu-pracy" */))
const _f7463be4 = () => interopDefault(import('../pages/kadry-i-place/kartoteka-pracownika.vue' /* webpackChunkName: "pages/kadry-i-place/kartoteka-pracownika" */))
const _18264641 = () => interopDefault(import('../pages/kadry-i-place/kasa-zapomogowo-pozyczkowa.vue' /* webpackChunkName: "pages/kadry-i-place/kasa-zapomogowo-pozyczkowa" */))
const _0ee3fcf5 = () => interopDefault(import('../pages/kadry-i-place/modul-rozliczen-z-pracownikami.vue' /* webpackChunkName: "pages/kadry-i-place/modul-rozliczen-z-pracownikami" */))
const _75116ce4 = () => interopDefault(import('../pages/kadry-i-place/obsluga-deklaracji-pfron.vue' /* webpackChunkName: "pages/kadry-i-place/obsluga-deklaracji-pfron" */))
const _555f1d50 = () => interopDefault(import('../pages/kadry-i-place/obsluga-ubezpieczen-grupowych-pracownikow.vue' /* webpackChunkName: "pages/kadry-i-place/obsluga-ubezpieczen-grupowych-pracownikow" */))
const _0da2dcd3 = () => interopDefault(import('../pages/kadry-i-place/roczna-ewidencja-czasu-pracy-pracownika.vue' /* webpackChunkName: "pages/kadry-i-place/roczna-ewidencja-czasu-pracy-pracownika" */))
const _16dcd7de = () => interopDefault(import('../pages/kadry-i-place/rozliczenie-godzin-pracownika.vue' /* webpackChunkName: "pages/kadry-i-place/rozliczenie-godzin-pracownika" */))
const _a5d641c0 = () => interopDefault(import('../pages/ksiegowosc-i-finanse/rozszerzenie-dla-modulu-fk-navireo.vue' /* webpackChunkName: "pages/ksiegowosc-i-finanse/rozszerzenie-dla-modulu-fk-navireo" */))
const _221d206c = () => interopDefault(import('../pages/ksiegowosc-i-finanse/wydruk-pieczeci-dekretacji.vue' /* webpackChunkName: "pages/ksiegowosc-i-finanse/wydruk-pieczeci-dekretacji" */))
const _d2f7fcac = () => interopDefault(import('../pages/nasze-realizacje/bielbit-dla-biznesu/index.vue' /* webpackChunkName: "pages/nasze-realizacje/bielbit-dla-biznesu/index" */))
const _88d7a9b4 = () => interopDefault(import('../pages/nasze-realizacje/mobilny-magazynier-na-androida.vue' /* webpackChunkName: "pages/nasze-realizacje/mobilny-magazynier-na-androida" */))
const _7aa30324 = () => interopDefault(import('../pages/nasze-realizacje/wcm-zintegrowany-system-zarzadzania.vue' /* webpackChunkName: "pages/nasze-realizacje/wcm-zintegrowany-system-zarzadzania" */))
const _e7805d8a = () => interopDefault(import('../pages/pomoc-dla-partnerow/formularz-zgloszen.vue' /* webpackChunkName: "pages/pomoc-dla-partnerow/formularz-zgloszen" */))
const _71518ff8 = () => interopDefault(import('../pages/pomoc-dla-partnerow/navireo-pobierz.vue' /* webpackChunkName: "pages/pomoc-dla-partnerow/navireo-pobierz" */))
const _07056984 = () => interopDefault(import('../pages/pomoc-dla-partnerow/nexo-pobierz.vue' /* webpackChunkName: "pages/pomoc-dla-partnerow/nexo-pobierz" */))
const _f52fcb7c = () => interopDefault(import('../pages/pomoc-dla-partnerow/statusy-zgloszen.vue' /* webpackChunkName: "pages/pomoc-dla-partnerow/statusy-zgloszen" */))
const _3b398f00 = () => interopDefault(import('../pages/sprzedaz-i-magazyny/inwentaryzacja-srodkow-trwalych.vue' /* webpackChunkName: "pages/sprzedaz-i-magazyny/inwentaryzacja-srodkow-trwalych" */))
const _c6856b94 = () => interopDefault(import('../pages/sprzedaz-i-magazyny/navireo-erp-wersja-dla-budownictwa.vue' /* webpackChunkName: "pages/sprzedaz-i-magazyny/navireo-erp-wersja-dla-budownictwa" */))
const _111843b3 = () => interopDefault(import('../pages/sprzedaz-i-magazyny/obsluga-rozliczenia-umow-z-cykliczna-platnoscia.vue' /* webpackChunkName: "pages/sprzedaz-i-magazyny/obsluga-rozliczenia-umow-z-cykliczna-platnoscia" */))
const _aab0769a = () => interopDefault(import('../pages/sprzedaz-i-magazyny/obsluga-zgloszen-serwisowych.vue' /* webpackChunkName: "pages/sprzedaz-i-magazyny/obsluga-zgloszen-serwisowych" */))
const _716ae206 = () => interopDefault(import('../pages/sprzedaz-i-magazyny/rabatowanie-i-obsluga-bonow-w-sklepach.vue' /* webpackChunkName: "pages/sprzedaz-i-magazyny/rabatowanie-i-obsluga-bonow-w-sklepach" */))
const _4e8690d6 = () => interopDefault(import('../pages/sprzedaz-i-magazyny/rozbudowany-modul-zamowien.vue' /* webpackChunkName: "pages/sprzedaz-i-magazyny/rozbudowany-modul-zamowien" */))
const _026020a0 = () => interopDefault(import('../pages/nasze-realizacje/bielbit-dla-biznesu/modul-obslugi-deklaracji-pfron.vue' /* webpackChunkName: "pages/nasze-realizacje/bielbit-dla-biznesu/modul-obslugi-deklaracji-pfron" */))
const _30d248a1 = () => interopDefault(import('../pages/nasze-realizacje/bielbit-dla-biznesu/pracownicza-kasa-zapomogowo-pozyczkowa.vue' /* webpackChunkName: "pages/nasze-realizacje/bielbit-dla-biznesu/pracownicza-kasa-zapomogowo-pozyczkowa" */))
const _4772d559 = () => interopDefault(import('../pages/nasze-realizacje/bielbit-dla-biznesu/wysylka-dokumentow-pracowniczych-na-ich-maile.vue' /* webpackChunkName: "pages/nasze-realizacje/bielbit-dla-biznesu/wysylka-dokumentow-pracowniczych-na-ich-maile" */))
const _66f6a276 = () => interopDefault(import('../pages/nasze-realizacje/bielbit-dla-biznesu/zakladowy-fundusz-mieszkaniowy.vue' /* webpackChunkName: "pages/nasze-realizacje/bielbit-dla-biznesu/zakladowy-fundusz-mieszkaniowy" */))
const _2e1a5c04 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktualnosci",
    component: _7d8c8294,
    name: "aktualnosci"
  }, {
    path: "/biuro-rachunkowe",
    component: _22e0349f,
    name: "biuro-rachunkowe"
  }, {
    path: "/cyberbezpieczenstwo",
    component: _3294b68e,
    name: "cyberbezpieczenstwo"
  }, {
    path: "/insert",
    component: _40f9fbba,
    name: "insert"
  }, {
    path: "/kadry-i-place",
    component: _b9582a40,
    name: "kadry-i-place"
  }, {
    path: "/kariera",
    component: _13f01659,
    name: "kariera"
  }, {
    path: "/kontakt",
    component: _91df465c,
    name: "kontakt"
  }, {
    path: "/ksiegowosc-i-finanse",
    component: _204a9e24,
    name: "ksiegowosc-i-finanse"
  }, {
    path: "/nasze-realizacje",
    component: _697ea2af,
    name: "nasze-realizacje"
  }, {
    path: "/o-nas",
    component: _516d09f4,
    name: "o-nas"
  }, {
    path: "/obowiazek-informacyjny-firmy",
    component: _f00f689a,
    name: "obowiazek-informacyjny-firmy"
  }, {
    path: "/oprogramowanie",
    component: _994c92b8,
    name: "oprogramowanie"
  }, {
    path: "/podpis-elektroniczny",
    component: _0b790f67,
    name: "podpis-elektroniczny"
  }, {
    path: "/pomoc-dla-partnerow",
    component: _2976d574,
    name: "pomoc-dla-partnerow"
  }, {
    path: "/sprzedaz-i-magazyny",
    component: _21885921,
    name: "sprzedaz-i-magazyny"
  }, {
    path: "/sprzet-i-serwis",
    component: _6c064a20,
    name: "sprzet-i-serwis"
  }, {
    path: "/insert/agralan",
    component: _25b15ae8,
    name: "insert-agralan"
  }, {
    path: "/insert/biuro-gt",
    component: _0f4d05f7,
    name: "insert-biuro-gt"
  }, {
    path: "/insert/biuro-nexo",
    component: _a8dbb210,
    name: "insert-biuro-nexo"
  }, {
    path: "/insert/czerwony-plus",
    component: _444f88f0,
    name: "insert-czerwony-plus"
  }, {
    path: "/insert/gestor-gt",
    component: _13f824a0,
    name: "insert-gestor-gt"
  }, {
    path: "/insert/gestor-gt-sfera",
    component: _aa404298,
    name: "insert-gestor-gt-sfera"
  }, {
    path: "/insert/gestor-nexo",
    component: _31f03f61,
    name: "insert-gestor-nexo"
  }, {
    path: "/insert/gestor-nexo-pro",
    component: _e563ab7e,
    name: "insert-gestor-nexo-pro"
  }, {
    path: "/insert/gratyfikant-gt",
    component: _71a7ec68,
    name: "insert-gratyfikant-gt"
  }, {
    path: "/insert/gratyfikant-gt-sfera",
    component: _153cb07c,
    name: "insert-gratyfikant-gt-sfera"
  }, {
    path: "/insert/gratyfikant-nexo",
    component: _3a6595ae,
    name: "insert-gratyfikant-nexo"
  }, {
    path: "/insert/gratyfikant-nexo-pro",
    component: _10aa07ee,
    name: "insert-gratyfikant-nexo-pro"
  }, {
    path: "/insert/insert-nexo-pro-plus",
    component: _1901b56d,
    name: "insert-insert-nexo-pro-plus"
  }, {
    path: "/insert/navireo",
    component: _d1247fc4,
    name: "insert-navireo"
  }, {
    path: "/insert/niebieski-plus",
    component: _2a07c45c,
    name: "insert-niebieski-plus"
  }, {
    path: "/insert/portal-biura",
    component: _717a2738,
    name: "insert-portal-biura"
  }, {
    path: "/insert/rachmistrz-gt",
    component: _d2a74a6e,
    name: "insert-rachmistrz-gt"
  }, {
    path: "/insert/rachmistrz-nexo",
    component: _4f20e16c,
    name: "insert-rachmistrz-nexo"
  }, {
    path: "/insert/rachmistrz-nexo-pro",
    component: _42615daa,
    name: "insert-rachmistrz-nexo-pro"
  }, {
    path: "/insert/rewizor-gt",
    component: _e81818ac,
    name: "insert-rewizor-gt"
  }, {
    path: "/insert/rewizor-gt-sfera",
    component: _3582be3e,
    name: "insert-rewizor-gt-sfera"
  }, {
    path: "/insert/rewizor-nexo",
    component: _cb97182a,
    name: "insert-rewizor-nexo"
  }, {
    path: "/insert/rewizor-nexo-pro",
    component: _17f109cb,
    name: "insert-rewizor-nexo-pro"
  }, {
    path: "/insert/sello",
    component: _1760b0c5,
    name: "insert-sello"
  }, {
    path: "/insert/subiekt-123",
    component: _6118d512,
    name: "insert-subiekt-123"
  }, {
    path: "/insert/subiekt-gt",
    component: _2919eabd,
    name: "insert-subiekt-gt"
  }, {
    path: "/insert/subiekt-gt-sfera",
    component: _99ac49de,
    name: "insert-subiekt-gt-sfera"
  }, {
    path: "/insert/subiekt-nexo",
    component: _f48e1b84,
    name: "insert-subiekt-nexo"
  }, {
    path: "/insert/subiekt-nexo-pro",
    component: _d4cfb2c4,
    name: "insert-subiekt-nexo-pro"
  }, {
    path: "/insert/subiekt-sprint-2",
    component: _ad87b7e2,
    name: "insert-subiekt-sprint-2"
  }, {
    path: "/insert/vendero",
    component: _66a47ff6,
    name: "insert-vendero"
  }, {
    path: "/insert/zarezerwuj-pl-system-rezerwacji-online",
    component: _1811fb69,
    name: "insert-zarezerwuj-pl-system-rezerwacji-online"
  }, {
    path: "/insert/zielony-plus",
    component: _0c75ee2a,
    name: "insert-zielony-plus"
  }, {
    path: "/kadry-i-place/fundusz-mieszkaniowo-remontowy",
    component: _7281f327,
    name: "kadry-i-place-fundusz-mieszkaniowo-remontowy"
  }, {
    path: "/kadry-i-place/fundusz-swiadczen-socjalnych",
    component: _1731540a,
    name: "kadry-i-place-fundusz-swiadczen-socjalnych"
  }, {
    path: "/kadry-i-place/import-indywidualnych-kalendarzy-czasu-pracy",
    component: _3e3f528b,
    name: "kadry-i-place-import-indywidualnych-kalendarzy-czasu-pracy"
  }, {
    path: "/kadry-i-place/kartoteka-pracownika",
    component: _f7463be4,
    name: "kadry-i-place-kartoteka-pracownika"
  }, {
    path: "/kadry-i-place/kasa-zapomogowo-pozyczkowa",
    component: _18264641,
    name: "kadry-i-place-kasa-zapomogowo-pozyczkowa"
  }, {
    path: "/kadry-i-place/modul-rozliczen-z-pracownikami",
    component: _0ee3fcf5,
    name: "kadry-i-place-modul-rozliczen-z-pracownikami"
  }, {
    path: "/kadry-i-place/obsluga-deklaracji-pfron",
    component: _75116ce4,
    name: "kadry-i-place-obsluga-deklaracji-pfron"
  }, {
    path: "/kadry-i-place/obsluga-ubezpieczen-grupowych-pracownikow",
    component: _555f1d50,
    name: "kadry-i-place-obsluga-ubezpieczen-grupowych-pracownikow"
  }, {
    path: "/kadry-i-place/roczna-ewidencja-czasu-pracy-pracownika",
    component: _0da2dcd3,
    name: "kadry-i-place-roczna-ewidencja-czasu-pracy-pracownika"
  }, {
    path: "/kadry-i-place/rozliczenie-godzin-pracownika",
    component: _16dcd7de,
    name: "kadry-i-place-rozliczenie-godzin-pracownika"
  }, {
    path: "/ksiegowosc-i-finanse/rozszerzenie-dla-modulu-fk-navireo",
    component: _a5d641c0,
    name: "ksiegowosc-i-finanse-rozszerzenie-dla-modulu-fk-navireo"
  }, {
    path: "/ksiegowosc-i-finanse/wydruk-pieczeci-dekretacji",
    component: _221d206c,
    name: "ksiegowosc-i-finanse-wydruk-pieczeci-dekretacji"
  }, {
    path: "/nasze-realizacje/bielbit-dla-biznesu",
    component: _d2f7fcac,
    name: "nasze-realizacje-bielbit-dla-biznesu"
  }, {
    path: "/nasze-realizacje/mobilny-magazynier-na-androida",
    component: _88d7a9b4,
    name: "nasze-realizacje-mobilny-magazynier-na-androida"
  }, {
    path: "/nasze-realizacje/wcm-zintegrowany-system-zarzadzania",
    component: _7aa30324,
    name: "nasze-realizacje-wcm-zintegrowany-system-zarzadzania"
  }, {
    path: "/pomoc-dla-partnerow/formularz-zgloszen",
    component: _e7805d8a,
    name: "pomoc-dla-partnerow-formularz-zgloszen"
  }, {
    path: "/pomoc-dla-partnerow/navireo-pobierz",
    component: _71518ff8,
    name: "pomoc-dla-partnerow-navireo-pobierz"
  }, {
    path: "/pomoc-dla-partnerow/nexo-pobierz",
    component: _07056984,
    name: "pomoc-dla-partnerow-nexo-pobierz"
  }, {
    path: "/pomoc-dla-partnerow/statusy-zgloszen",
    component: _f52fcb7c,
    name: "pomoc-dla-partnerow-statusy-zgloszen"
  }, {
    path: "/sprzedaz-i-magazyny/inwentaryzacja-srodkow-trwalych",
    component: _3b398f00,
    name: "sprzedaz-i-magazyny-inwentaryzacja-srodkow-trwalych"
  }, {
    path: "/sprzedaz-i-magazyny/navireo-erp-wersja-dla-budownictwa",
    component: _c6856b94,
    name: "sprzedaz-i-magazyny-navireo-erp-wersja-dla-budownictwa"
  }, {
    path: "/sprzedaz-i-magazyny/obsluga-rozliczenia-umow-z-cykliczna-platnoscia",
    component: _111843b3,
    name: "sprzedaz-i-magazyny-obsluga-rozliczenia-umow-z-cykliczna-platnoscia"
  }, {
    path: "/sprzedaz-i-magazyny/obsluga-zgloszen-serwisowych",
    component: _aab0769a,
    name: "sprzedaz-i-magazyny-obsluga-zgloszen-serwisowych"
  }, {
    path: "/sprzedaz-i-magazyny/rabatowanie-i-obsluga-bonow-w-sklepach",
    component: _716ae206,
    name: "sprzedaz-i-magazyny-rabatowanie-i-obsluga-bonow-w-sklepach"
  }, {
    path: "/sprzedaz-i-magazyny/rozbudowany-modul-zamowien",
    component: _4e8690d6,
    name: "sprzedaz-i-magazyny-rozbudowany-modul-zamowien"
  }, {
    path: "/nasze-realizacje/bielbit-dla-biznesu/modul-obslugi-deklaracji-pfron",
    component: _026020a0,
    name: "nasze-realizacje-bielbit-dla-biznesu-modul-obslugi-deklaracji-pfron"
  }, {
    path: "/nasze-realizacje/bielbit-dla-biznesu/pracownicza-kasa-zapomogowo-pozyczkowa",
    component: _30d248a1,
    name: "nasze-realizacje-bielbit-dla-biznesu-pracownicza-kasa-zapomogowo-pozyczkowa"
  }, {
    path: "/nasze-realizacje/bielbit-dla-biznesu/wysylka-dokumentow-pracowniczych-na-ich-maile",
    component: _4772d559,
    name: "nasze-realizacje-bielbit-dla-biznesu-wysylka-dokumentow-pracowniczych-na-ich-maile"
  }, {
    path: "/nasze-realizacje/bielbit-dla-biznesu/zakladowy-fundusz-mieszkaniowy",
    component: _66f6a276,
    name: "nasze-realizacje-bielbit-dla-biznesu-zakladowy-fundusz-mieszkaniowy"
  }, {
    path: "/",
    component: _2e1a5c04,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
